import { createApp, ref } from 'vue'
import App from './App.vue'

const app = createApp(App)

// * Store
import { store } from "@/store";
app.use(store)

// * PrimeVue
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Editor from 'primevue/editor';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import InlineMessage from 'primevue/inlinemessage';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Listbox from 'primevue/listbox';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import Tree from 'primevue/tree';
import TreeTable from 'primevue/treetable';
import MultiSelect from 'primevue/multiselect';
import OverlayPanel from 'primevue/overlaypanel';
import Panel from 'primevue/panel';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import SelectButton from 'primevue/selectbutton';
import Sidebar from 'primevue/sidebar'
import Slider from 'primevue/slider';
import SplitButton from 'primevue/splitbutton';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Timeline from 'primevue/timeline';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
import Toolbar from 'primevue/toolbar';

import "primevue/resources/primevue.min.css";
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "@/assets/theme/theme.scss";

app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Card', Card);
app.component('Calendar', Calendar);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Chips', Chips);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('Editor', Editor);
app.component('Fieldset', Fieldset);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Listbox', Listbox);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OverlayPanel', OverlayPanel);
app.component('Panel', Panel);
app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('SelectButton', SelectButton);
app.component('Slider', Slider);
app.component('SplitButton', SplitButton);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Textarea', Textarea);
app.component('TieredMenu', TieredMenu);
app.component('Tree', Tree);
app.component('TreeTable', TreeTable);
app.component('FileUpload', FileUpload);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('ToggleButton', ToggleButton);
app.component('Timeline', Timeline);
app.component('InlineMessage', InlineMessage);
app.component('Sidebar', Sidebar);
app.component('ProgressSpinner', ProgressSpinner);

app.directive('tooltip', Tooltip);

app.use(PrimeVue, {
  ripple: false,
  locale: {
    startsWith: 'Inizia con',
    contains: 'Contiene',
    notContains: 'Non contiene',
    endsWith: 'Finisce coh',
    equals: 'Uguale',
    notEquals: 'Non uguale',
    noFilter: 'Nessun filtro',
    lt: 'Minore di',
    lte: 'Minore o uguale a',
    gt: 'Maggiore di',
    gte: 'Maggiore o uguale a',
    dateIs: 'La data è',
    dateIsNot: 'La data non è',
    dateBefore: 'La data è precedente',
    dateAfter: 'La data è successiva',
    clear: 'Reset',
    apply: 'Applica',
    matchAll: 'Tutti',
    matchAny: 'Alcuni',
    addRule: 'Aggiungi regola',
    removeRule: 'Rimuovi regola',
    accept: 'Sì',
    reject: 'No',
    choose: 'File',
    upload: 'Carica',
    cancel: 'Annulla',
    dayNames: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
    dayNamesMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
    monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
    monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
    today: 'Oggi',
    weekHeader: 'Set',
    firstDayOfWeek: 1,
    dateFormat: 'dd/mm/yy',
    weak: 'Weak',
    medium: 'Medium',
    strong: 'Strong',
    passwordPrompt: 'Inserisci una password',
    emptyFilterMessage: 'Nessun risultato',
    emptyMessage: 'Nessuna opzione disponibile'
  },
});

app.use(ConfirmationService);
app.use(ToastService);

// * Components
import Loghi from '@/components/Loghi.vue';
import FormInputText from '@/components/FormInputText.vue';
import SubmitBtn from '@/components/SubmitBtn.vue';
import VotesTable from '@/components/VotesTable.vue';

app.component('Loghi', Loghi);
app.component('FormInputText', FormInputText);
app.component('SubmitBtn', SubmitBtn);
app.component('VotesTable', VotesTable);

// * Icons
// https://ant.design/components/icon/
import { CheckCircleTwoTone, MailTwoTone, MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons-vue';
app.component('CheckCircleTwoTone', CheckCircleTwoTone);
app.component("MailTwoTone", MailTwoTone);
app.component('MinusCircleTwoTone', MinusCircleTwoTone);
app.component('PlusCircleTwoTone', PlusCircleTwoTone);

// app.config.globalProperties.$moment = moment;

// * Layout
const layout = ref('')
app.provide('layout', layout)


// * Router
import router from './router'

app.use(router)

router.afterEach((route) => {
  console.log(`[ROUTE] ${route.path}`);
  console.log(store);

  // if (route.path.includes('/questionario/')) {
  //   // const questionnaireId = route.path.split('/questionario/')[1];
  //   // TODO: store.dispatch(QUESTIONNARIRE_STORE_ID, questionnaireId);
  // }

  // Layout
  layout.value = route.meta.layout as string
});

// * Mount
app.mount('#app')



