
import { errorCallback, hideLoader, showLoader } from "@/helpers/helpers";
import { retrieveLocationIdFromUrl, retrieveLocationIdQueryFromUrl } from "@/helpers/location";
import { Location } from "@/models/Location";
import {
  LOCATIONS_ACTIONS
} from "@/store/modules/locations";
import { useToast } from "primevue/usetoast";
import { defineComponent, nextTick, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Vote } from "@/models/Vote";
import router from "@/router";

export default defineComponent({
  name: "ContestThankyoupageView",

  setup() {
    // * Helpers
    const route = useRoute();
    const store = useStore();
    const toast = useToast();

    // * Computed

    // * Data
    const votes = ref<Array<Vote> | null>(null);
    const location = ref<Location | null>(null);
    const locationId = ref<string>();

    // * Methods
    const fetchContest = async function (contestId) {
        try {
          const response = await store.dispatch(LOCATIONS_ACTIONS.FETCH_CONTEST, contestId);
          votes.value = response.votes;
          const locations = response.contest.locations;
          location.value = locations.filter(x => x.id === locationId.value)[0];
          return response;
        } catch (err) {
          hideLoader(store);
          errorCallback(toast, err);
        }
      };

    const init = async function () {
      const contestId = retrieveLocationIdFromUrl(route);
      locationId.value = retrieveLocationIdQueryFromUrl(route);
      if (!locationId.value && !contestId) {
        console.error("NON TROVO locationId e contestId");
        router.push({ name: "errorpage" });
      } 
      showLoader(store);
      await fetchContest(contestId);
      hideLoader(store);
    };

    // * Init
    nextTick(async () => {
      init();
    });
    return {
      // * Data

      // * Methods
      fetchContest,

      // * Computed
      location,
      votes,
    };
  },
});
