
import { computed, defineComponent, inject } from "vue";

import DefaultLayout from "@/layouts/DefaultLayout.vue";

import { IS_LOADER_VISIBLE } from "./store/modules/app";
import { useStore } from "vuex";

export default defineComponent({
  name: "App",

  components: {
    DefaultLayout
  },

  setup() {
    // DATA
    const layout = inject("layout") as string;

    // COMPUTED
    const isLoaderVisible = computed(
      () => useStore().getters[IS_LOADER_VISIBLE]
    );

    return {
      // DATA
      layout,

      // COMPUTED
      isLoaderVisible,
    };
  },
});
