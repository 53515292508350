import { Location } from "@/models/Location";
import { Vote } from "@/models/Vote";
import { apiGet } from "./api-service";

export class LocationService {
  static async fetchLocation(id:string): Promise<Location> {
    const response = await apiGet(`/locations/${id}`);
    return response.data.location as Location;
  }

  static async fetchAllLocations(): Promise<Array<Location>> {
    const response = await apiGet(`/locations`);
    return response.data.locations as Array<Location>;
  }

  static async fetchLocations(id:string): Promise<Array<Location>> {
    const response = await apiGet(`/contests/${id}`);
    return response.data.contest.locations as Array<Location>;
  }

  static async fetchContest(id:string): Promise<any> {
    const response = await apiGet(`/contests/${id}`);
    return response.data;
  }

  static async fetchVotes(): Promise<Array<Vote>> {
    const response = await apiGet(`/votes`);
    return response.data.votes as Array<Vote>;
  }

}