
import { defineComponent } from "vue";

export default defineComponent({
  name: "VotesTable",
  props: {
    votes: {
      required: true,
      type: Array,
    },
  }
});
