import Vuex from 'vuex'
import appModule, { AppState } from './modules/app'
import locationsModule, { LocationsState } from './modules/locations';
import votesModule, { VotesState }  from './modules/votes';

export interface State {
  app: AppState;
  locations: LocationsState;
  votes: VotesState;
}

export const store = new Vuex.Store<State>({
  strict: true,
  modules: {
    app: appModule,
    locations: locationsModule,
    votes: votesModule
  }
})