// import axios from "axios";
export const HIDE_LOADER = "app/hideLoader"
export const IS_LOADER_VISIBLE = "app/isLoaderVisible"
export const SHOW_LOADER = "app/showLoader"

export interface AppState {
  loaderVisible: boolean,
  toastVisible: boolean,
  locationId: string | null,
}

export default {
  namespaced: true,

  state: (): AppState => ({
    loaderVisible: false,
    toastVisible: false,
    locationId: null,
  }),

  actions: {
    hideLoader(context) {
      context.commit('setLoaderVisible', false)
    },

    showLoader(context) {
      context.commit('setLoaderVisible', true)
    },

    // FIXME: da spostare in questionnarires
    async storeLocationId(context: any, id: string) {
      context.commit('saveLocationId', id);
    }
  },

  getters: {
    isLoaderVisible: (state: AppState): boolean => state.loaderVisible,
  },

  mutations: {
    setLoaderVisible(state: AppState, visible: boolean) {
      state.loaderVisible = visible
    },


    saveLocationId(state: AppState, id: string) {
      state.locationId = id;
    },
  }
}