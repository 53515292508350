import { Location } from "@/models/Location";
import { LocationService } from "@/services/api/location-service";

export interface LocationsState {
  locations: Array<Location>,
  location: Location | null,
  locationId: string | null,
}

export const LOCATIONS_GETTERS = {
  LOCATION_ID: 'locations/locationId',
  CURRENT_LOCATION: 'locations/currentLocation',
  ALL_LOCATIONS: 'locations/allLocations'
};

export const LOCATIONS_ACTIONS = {
  FETCH_LOCATION: 'locations/fetchLocation',
  FETCH_LOCATIONS: 'locations/fetchLocations',
  STORE_ID: 'locations/storeLocationId',
  FETCH_CONTEST: 'locations/fetchContest',
  FETCH_ALL_LOCATIONS: 'locations/fetchAllLocations'
}


const localStorageLocationId = localStorage.getItem('locationId');
const storedLocationId = localStorageLocationId ? JSON.parse(localStorageLocationId) as string : null;

export default {
  namespaced: true,

  state: (): LocationsState => ({
    locations: [],
    location: null,
    locationId: storedLocationId
  }),

  actions: {
    async fetchLocation(context: any) {
      LocationService.fetchLocation(`${context.state.locationId}`).then(
        result => {
          context.commit('setLocation', result);
        },
        error => {
          console.error(error);
          context.commit('setLocation', []);
        });
    },

    async storeLocationId(context: any, locationId: string) {
      context.commit('storeLocationId', locationId);
    },

    async fetchLocations(context: any, contestId: string) {
      LocationService.fetchLocations(`${contestId}`).then(
        result => {
          context.commit('setLocations', result);
        },
        error => {
          console.error(error);
          context.commit('setLocations', []);
        });
    },

    async fetchAllLocations(context: any) {
      LocationService.fetchAllLocations().then(
        result => {
          context.commit('setLocations', result);
        },
        error => {
          console.error(error);
          context.commit('setLocations', []);
        });
    },

    async fetchContest(context: any, contestId: string) {
      return LocationService.fetchContest(`${contestId}`);
    },
  },

  getters: {
    currentLocation: (state: LocationsState) => {
      if (state.location != null) {
        return state.location
      }
      return null
    },

    allLocations: (state: LocationsState) => {
      if (state.locations != null) {
        return state.locations
      }
      return null
    },

    locationId: (state: LocationsState) => state.locationId,
  },

  mutations: {
    setLocation(state: LocationsState, location: Location) {
      state.location = location;
    },

    setLocations(state: LocationsState, locations: Array<Location>) {
      state.locations = locations;
    },

    storeLocationId(state: LocationsState, locationId: string) {
      state.locationId = locationId;
      localStorage.setItem('locationId', JSON.stringify(locationId));
    }
  }
}

