
import {
  errorCallback,
  hideLoader,
  showLoader,
  showToast,
} from "@/helpers/helpers";
import { retrieveLocationIdFromUrl } from "@/helpers/location";
import { email, sameAs, required } from "@vuelidate/validators";
import { Location } from "@/models/Location";
import { useVuelidate } from "@vuelidate/core";
import {
  LOCATIONS_ACTIONS,
  LOCATIONS_GETTERS,
} from "@/store/modules/locations";
import { useToast } from "primevue/usetoast";
import { computed, defineComponent, nextTick, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { VOTES_ACTIONS } from "@/store/modules/votes";
import router from "@/router";

export default defineComponent({
  name: "LocationView",

  setup() {
    // * Helpers
    const route = useRoute();
    const store = useStore();
    const toast = useToast();

    // * Computed
    const location = computed(
      (): Location => store.getters[LOCATIONS_GETTERS.CURRENT_LOCATION]
    );
    const locationId = computed(
      (): Location => store.getters[LOCATIONS_GETTERS.LOCATION_ID]
    );

    // * Data
    const formSubmitted = ref(false);
    const formState = reactive({
      email: "",
      terms: null,
    });

    const formRules = {
      email: {
        required,
        email,
      },
      terms: {
        required,
        sameAs: sameAs(true),
      },
    };
    const v$ = useVuelidate(formRules, formState);

    // * Methods
    const fetchLocation = async function () {
      try {
        const result = await store.dispatch(LOCATIONS_ACTIONS.FETCH_LOCATION);
        return result;
      } catch (err) {
        hideLoader(store);
        errorCallback(toast, err);
      }
    };

    const handleSubmit = (isFormValid) => {
      formSubmitted.value = true;

      if (!isFormValid) {
        return;
      }

      const params = {
        email: formState.email,
        location_id: locationId.value,
      };

      showLoader(store);

      // * Submit
      store
        .dispatch(VOTES_ACTIONS.VOTES_LOCATION, params)
        .then(() => {
          hideLoader(store);
          showToast(toast, {
            message: "Controlla la tua e-mail per confermare il voto.",
            title: "Grazie per aver votato!",
          });
        })
        .catch((err) => {
          hideLoader(store);
          errorCallback(toast, err);
        });
    };

    const init = async function () {
      const argLocationId = retrieveLocationIdFromUrl(route);
      if (!argLocationId) {
        console.error("NON TROVO locationId");
        router.push({ name: "errorpage" });
      }
      // Salvo l'id della location
      store.dispatch(LOCATIONS_ACTIONS.STORE_ID, argLocationId);
      showLoader(store);
      await fetchLocation();
      hideLoader(store);
    };

    // * Init
    nextTick(async () => {
      init();
    });
    return {
      // * Data
      formSubmitted,
      v$,

      // * Methods
      fetchLocation,
      handleSubmit,

      // * Computed
      location,
    };
  },
});
