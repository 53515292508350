
import { errorCallback, hideLoader, showLoader } from "@/helpers/helpers";
import { Location } from "@/models/Location";
import {
  LOCATIONS_ACTIONS,
  LOCATIONS_GETTERS,
} from "@/store/modules/locations";
import { useToast } from "primevue/usetoast";
import { computed, defineComponent, nextTick, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { VOTES_ACTIONS, VOTES_GETTERS } from "@/store/modules/votes";
import { Vote } from "@/models/Vote";
import router from "@/router";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default defineComponent({
  name: "HomeView",

  setup() {
    // * Helpers
    const store = useStore();
    const toast = useToast();

    // * Computed

    const votes = computed(
      (): Array<Vote> => store.getters[VOTES_GETTERS.ALL_VOTES]
    );

    const locations = computed(
      (): Array<Location> => store.getters[LOCATIONS_GETTERS.ALL_LOCATIONS]
    );

    // * Data
    const location = Location;
    const formSubmitted = ref(false);
    const formState = reactive({
      location: null,
    });
    const formRules = {
      location: {
        required,
      },
    };
    const v$ = useVuelidate(formRules, formState);

    // * Methods
    const fetchLocations = async function () {
      try {
        const response = await store.dispatch(
          LOCATIONS_ACTIONS.FETCH_ALL_LOCATIONS
        );
        return response;
      } catch (err) {
        hideLoader(store);
        errorCallback(toast, err);
      }
    };

    const fetchVotes = async function () {
      try {
        const result = await store.dispatch(VOTES_ACTIONS.FETCH_VOTES);
        return result;
      } catch (err) {
        hideLoader(store);
        errorCallback(toast, err);
      }
    };

    const handleSubmit = (isFormValid) => {
      formSubmitted.value = true;

      if (!isFormValid) {
        return;
      }

      const location = formState ? formState.location : null;

      if(location && location["id"]){
        router.push({ name: 'locali' , params: { id: location["id"] }})
      }

    };

    const init = async function () {
      showLoader(store);
      await fetchLocations();
      await fetchVotes();
      hideLoader(store);
    };

    // * Init
    nextTick(async () => {
      init();
    });
    return {
      // * Data
      formSubmitted,
      v$,

      // * Methods
      fetchLocations,
      fetchVotes,
      handleSubmit,

      // * Computed
      votes,
      locations,
      location,
    };
  },
});
