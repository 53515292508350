import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-content-end mt-2 lg:mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      type: "submit",
      label: _ctx.label,
      class: _normalizeClass({'w-100': _ctx.width === 'w-100', 'mt-4': true}),
      disabled: _ctx.disabled
    }, null, 8, ["label", "class", "disabled"])
  ]))
}