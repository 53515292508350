
  import { errorCallback, hideLoader, showLoader, showToast } from "@/helpers/helpers";
  import { retrieveLocationIdFromUrl } from "@/helpers/location";
  import { email, sameAs, required } from "@vuelidate/validators";
  import { Location } from "@/models/Location";
  import { useVuelidate } from "@vuelidate/core";
  import {
    LOCATIONS_ACTIONS,
    LOCATIONS_GETTERS,
  } from "@/store/modules/locations";
  import { useToast } from "primevue/usetoast";
  import { computed, defineComponent, nextTick, reactive, ref } from "vue";
  import { useRoute } from "vue-router";
  import { useStore } from "vuex";
  import { VOTES_ACTIONS } from "@/store/modules/votes";
  import router from "@/router";
  
  export default defineComponent({
    name: "ContestView",
  
    setup() {
      // * Helpers
      const route = useRoute();
      const store = useStore();
      const toast = useToast();
  
      // * Computed
      const locations = computed(
        (): Array<Location> => store.getters[LOCATIONS_GETTERS.ALL_LOCATIONS]
      );
  
      // * Data
      const contest_id = ref("");
      const formSubmitted = ref(false);
      const formState = reactive({
        email: "",
        location: null,
        terms: null,
      });
  
      const formRules = {
        email: {
          required,
          email,
        },
        location: {
          required
        },
        terms: {
          required,
          sameAs: sameAs(true),
        },
      };
      const v$ = useVuelidate(formRules, formState);
  
      // * Methods
      const fetchLocations = async function (locationId) {
        try {
          const response = await store.dispatch(LOCATIONS_ACTIONS.FETCH_LOCATIONS, locationId);
          return response;
        } catch (err) {
          hideLoader(store);
          errorCallback(toast, err);
        }
      };
  
      const handleSubmit = (isFormValid) => {
        formSubmitted.value = true;
  
        if (!isFormValid) {
          return;
        }
  
        const params = {
          email: formState.email,
          location_id: formState.location ? formState.location["id"]: null,
          contest_id: contest_id.value
        };
  
        showLoader(store);
  
        // * Submit
        store
          .dispatch(VOTES_ACTIONS.VOTES_LOCATION, params)
          .then(() => {
            hideLoader(store);
            showToast(toast, {
                message: "Controlla la tua e-mail per confermare il voto.",
                title: "Grazie per aver votato!",
              });
          })
          .catch((err) => {
            hideLoader(store);
            errorCallback(toast, err);
          });
      };
  
      const init = async function () {
        contest_id.value = retrieveLocationIdFromUrl(route);
      if (!contest_id.value) {
        console.error("NON TROVO locationId");
        router.push({ name: "errorpage" });
      }
        showLoader(store);
        await fetchLocations(contest_id.value);
        hideLoader(store);
      };
  
      // * Init
      nextTick(async () => {
        init();
      });
      return {
        // * Data
        formSubmitted,
        v$,
  
        // * Methods
        fetchLocations,
        handleSubmit,
  
        // * Computed
        locations,
      };
    },
  });
  