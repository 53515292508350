import { Vote } from "@/models/Vote";
import { apiGet, apiPost } from "./api-service";

export class VoteService {
  static async fetchVotes(): Promise<Array<Vote>> {
    const response = await apiGet(`/votes`);
    return response.data.votes as Array<Vote>;
  }
  static async votesLocation(params: Map<string, any>) {
    await apiPost(`/votes`, params);
    return;
  }

}