import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LocationView from '@/views/location/LocationView.vue'
import ContestView from '@/views/contest/ContestView.vue'
import HomeView from '@/views/home/HomeView.vue'
import ThankyoupageView from '@/views/thankyoupage/ThankyoupageView.vue'
import ErrorView from '@/views/error/ErrorView.vue'
import ContestThankyoupageView from '@/views/thankyoupage/ContestThankyoupageView.vue'


const routes: Array<RouteRecordRaw> = [
  {
    component: HomeView,
    name: 'root',
    meta: {
      layout: 'DefaultLayout',
      guest: true,
    },
    path: '/',
  },
  {
    component: LocationView,
    name: 'locali',
    meta: {
      layout: 'DefaultLayout',
      guest: true,
    },
    path: '/locali/:id',
  },
  {
    component: ContestView,
    name: 'contest',
    meta: {
      layout: 'DefaultLayout',
      guest: true,
    },
    path: '/contest/:id',
  },
  {
    component: ThankyoupageView,
    name: 'thankyoupage',
    meta: {
      layout: 'DefaultLayout',
      guest: true,
    },
    path: '/votes/:id/confirm',
  },
  {
    component: ErrorView,
    name: 'errorpage',
    meta: {
      layout: 'DefaultLayout',
      guest: true,
    },
    path: "/:catchAll(.*)*"
  },
  {
    component: ContestThankyoupageView,
    name: 'contestThankyoupage',
    meta: {
      layout: 'DefaultLayout',
      guest: true,
    },
    path: '/contest-votes/:id/confirm',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router


