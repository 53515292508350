
import { errorCallback, hideLoader, showLoader } from "@/helpers/helpers";
import { retrieveLocationIdFromUrl } from "@/helpers/location";
import { Location } from "@/models/Location";
import {
  LOCATIONS_ACTIONS,
  LOCATIONS_GETTERS,
} from "@/store/modules/locations";
import { useToast } from "primevue/usetoast";
import { computed, defineComponent, nextTick } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { VOTES_ACTIONS, VOTES_GETTERS } from "@/store/modules/votes";
import { Vote } from "@/models/Vote";

export default defineComponent({
  name: "ThankyoupageView",

  setup() {
    // * Helpers
    const route = useRoute();
    const store = useStore();
    const toast = useToast();

    // * Computed
    const location = computed(
      (): Location => store.getters[LOCATIONS_GETTERS.CURRENT_LOCATION]
    );

    const votes = computed(
      (): Array<Vote> => store.getters[VOTES_GETTERS.ALL_VOTES]
    );
    // * Data

    // * Methods
    const fetchLocation = async function () {
      try {
        const result = await store.dispatch(LOCATIONS_ACTIONS.FETCH_LOCATION);
        return result;
      } catch (err) {
        hideLoader(store);
        errorCallback(toast, err);
      }
    };

    const fetchVotes = async function () {
      try {
        const result = await store.dispatch(VOTES_ACTIONS.FETCH_VOTES);
        return result;
      } catch (err) {
        hideLoader(store);
        errorCallback(toast, err);
      }
    };

    const init = async function () {
      const argLocationId = retrieveLocationIdFromUrl(route);
      if (!argLocationId) {
        console.error("NON TROVO locationId");
      } 
      // Salvo l'id della location
      store.dispatch(LOCATIONS_ACTIONS.STORE_ID, argLocationId);
      showLoader(store);
      await fetchLocation();
      await fetchVotes();
      hideLoader(store);
    };

    // * Init
    nextTick(async () => {
      init();
    });
    return {
      // * Data

      // * Methods
      fetchLocation,
      fetchVotes,

      // * Computed
      location,
      votes
    };
  },
});
