import { RouteLocationNormalizedLoaded } from "vue-router";

const retrieveLocationIdFromUrl = function (route: RouteLocationNormalizedLoaded): string {
  if (route.params['id'] !== undefined) {
    return route.params['id'] as string;
  }
  return '';
}

const retrieveLocationIdQueryFromUrl = function (route: RouteLocationNormalizedLoaded): string {
  if (route.query['location_id'] !== undefined) {
    return route.query['location_id'] as string;
  }
  return '';
}

export {
  retrieveLocationIdFromUrl,
  retrieveLocationIdQueryFromUrl
}
