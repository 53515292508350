import { Vote } from "@/models/Vote";
import { VoteService } from "@/services/api/vote-service";

export interface VotesState {
  votes: Array<Vote>,
  vote: Vote | null,
}

export const VOTES_GETTERS = {
  ALL_VOTES: 'votes/allVotes'
};

export const VOTES_ACTIONS = {
  FETCH_VOTES: 'votes/fetchVotes',
  VOTES_LOCATION: 'votes/votesLocation',
}

export default {
  namespaced: true,

  state: (): VotesState => ({
    votes: [],
    vote: null
  }),

  actions: {
    async fetchVotes(context: any) {
      return VoteService.fetchVotes().then(
        result => {
          context.commit('setVotes', result);
        },
        error => {
          console.error(error);
          context.commit('setVotes', null);
        });
    },

    async votesLocation(context: any, params: Map<string, any>) {
      await VoteService.votesLocation(params)
    }
  },

  getters: {
    allVotes: (state: VotesState) => { return state.votes}
  },

  mutations: {
    setVotes(state: VotesState, votes: Array<Vote>) {
      state.votes = votes;
    },
  }
}

