<template>
  <div id="loghi" class="mt-2 mb-6">
    <!-- <img src="@/assets/loghi.png"  /> -->
    <p class="text-primary mt-4"><b>Diamond Partner</b></p>
    <div class="flex align-items-center flex-wrap diamond">
     <img src="https://d9hhrg4mnvzow.cloudfront.net/www.cocktailweektorino.it/ae901f1a-156-1568682-beam-suntory-png-logo-of-beam-suntory-transparent_1049024000000000000028.png" />
     <img src="https://d9hhrg4mnvzow.cloudfront.net/www.cocktailweektorino.it/cd41afd6-stocklogo-italy-fullcolour-2020-1_104r02k000000000000028.png" />
     <img src="https://d9hhrg4mnvzow.cloudfront.net/www.cocktailweektorino.it/43eae000-jim-beam-logo_105w03b04q03b00l000028.png" />
     <img src="https://d9hhrg4mnvzow.cloudfront.net/www.cocktailweektorino.it/98d0c114-haku-logo-scaled-edited_104j029000000000000028.png" />
     <img src="https://d9hhrg4mnvzow.cloudfront.net/www.cocktailweektorino.it/3dbcb002-download-1_107n03a07402j00a009028.png" />
    </div>

    <p class="text-primary mt-4"><b>Golden Partner</b></p>
    <div class="flex align-items-center flex-wrap golden">
      <img src="https://d9hhrg4mnvzow.cloudfront.net/www.cocktailweektorino.it/c5722958-carpano1011030.svg" />
      <img src="https://d9hhrg4mnvzow.cloudfront.net/www.cocktailweektorino.it/3b7c4801-b66d466c-logo-ginto-png-103e03e000000000000028-1_102o02o02o028000008028.png" />
      <img src="https://d9hhrg4mnvzow.cloudfront.net/www.cocktailweektorino.it/cf629e71-logo-winedora_104202t03l028008008028.png" />
      <img src="https://d9hhrg4mnvzow.cloudfront.net/www.cocktailweektorino.it/b37c4a6a-sake-company-logo_104001e000000000000028.jpeg" />
      <img src="https://d9hhrg4mnvzow.cloudfront.net/www.cocktailweektorino.it/1752a2c6-outlook-5r0blklw_103x01p000000000000028.png" />
      <img src="https://d9hhrg4mnvzow.cloudfront.net/www.cocktailweektorino.it/6173bacd-villa-m1011030.svg" />
      <img src="https://d9hhrg4mnvzow.cloudfront.net/www.cocktailweektorino.it/718862a9-taggiasco1011030.svg" />
      <img src="https://d9hhrg4mnvzow.cloudfront.net/www.cocktailweektorino.it/6d83c290-borghetti1011030.svg" />
      <img src="https://d9hhrg4mnvzow.cloudfront.net/www.cocktailweektorino.it/d4f33da4-tsm-logosfondobianco_104804603602500j011028.png" />
      <img src="https://d9hhrg4mnvzow.cloudfront.net/www.cocktailweektorino.it/68ae64a4-logo-cocchi-png_102t02e000000000000028.png" />
      <img src="https://d9hhrg4mnvzow.cloudfront.net/www.cocktailweektorino.it/38f23fb9-fernet1011030.svg" />
      <img src="https://d9hhrg4mnvzow.cloudfront.net/www.cocktailweektorino.it/2b8cfaff-schermata-2023-09-08-alle-18-07-10_104f02b000000000000028.png" />
      <img src="https://d9hhrg4mnvzow.cloudfront.net/www.cocktailweektorino.it/05c23809-schermata-2023-09-08-alle-15-12-54_103l02j03b025004005028.png" />
      <img src="https://d9hhrg4mnvzow.cloudfront.net/www.cocktailweektorino.it/e39b3dc6-spirits-select_103g03g03g02d00000i028.png" />
      <img src="https://d9hhrg4mnvzow.cloudfront.net/www.cocktailweektorino.it/7269ab58-punt-e-mes1011030.svg" />
      <img src="https://d9hhrg4mnvzow.cloudfront.net/www.cocktailweektorino.it/948b5d84-schermata-2023-09-09-alle-16-44-55_104g022000000000000028.png" />
      <img src="https://d9hhrg4mnvzow.cloudfront.net/www.cocktailweektorino.it/e52f72ca-logo-black-800x266_1035012000000000000028.png" />
    </div>
  </div>
</template>

<style>
#loghi {
  margin: auto;
  max-width: 1200px; 
  width: 98%; 
  padding: 2rem 1rem;
}
#loghi p{
    font-size: 1.1rem;
}
#loghi img {
  width: 136px;
  margin: 1rem 1.5rem;
  max-height: 90px;
}

@media screen and (max-width: 700px){
  #loghi .diamond img{
    width: 100px;
}
  #loghi .golden img{
    width: 66px;
  }
}
</style>
